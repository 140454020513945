<template>
  <div class=container>
      <table width="100%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"><font size="+2">Gallery of Separation Results</font></div></td>
          </tr>
          <tr>
            <td>Here, we show several examples of our separation results. In each case, we mention the separation method we have used. In all cases, the scene image is the sum of the computed direct and global images. However, to bring out the details, for some of the scenes we have brightened the direct and global images by a scale factor (between 1 and 2) with respect to the scene image. <strong>By clicking on each of the thumbnail images shown below, you can see the corresponding high resolution image.</strong><br>
            </td>
          </tr>
        <router-link :to="{path:'/projects/categories/project', query:{cid:'Computational Imaging',pid:'Fast Separation of Direct and Global Images'}}">(Back to Main Project Page) </router-link>
          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                <tbody><tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="47%"><strong>Hands of Different Nationalities</strong></td>
                        <td width="53%"><div align="right"><strong>Method : Checkerboard<br>
                        </strong></div></td>
                      </tr>
                    </tbody></table>
                      <div align="right"></div></td>
                </tr>
                <tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/AfriAmer_Hand_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_AfriAmer_Hand_Scene.jpg" width="203" height="111" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/AfriAmer_Hand_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_AfriAmer_Hand_Direct.jpg" width="203" height="111" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/AfriAmer_Hand_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_AfriAmer_Hand_Global.jpg" width="203" height="111" border="0"></a></td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td><div align="center">(a)</div></td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Indian_Hand_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_Indian_Hand_Scene.jpg" width="203" height="111" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Indian_Hand_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_Indian_Hand_Direct.jpg" width="203" height="111" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Indian_Hand_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_Indian_Hand_Global.jpg" width="203" height="111" border="0"></a></td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td><div align="center">(b)</div></td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Spanish_Hand_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_Spanish_Hand_Scene.jpg" width="203" height="111" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Spanish_Hand_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_Spanish_Hand_Direct.jpg" width="203" height="111" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Spanish_Hand_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_Spanish_Hand_Global.jpg" width="203" height="111" border="0"></a></td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td><div align="center">(c)</div></td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Chinese_Hand_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_Chinese_Hand_Scene.jpg" width="203" height="111" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Chinese_Hand_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_Chinese_Hand_Direct.jpg" width="203" height="111" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Chinese_Hand_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_Chinese_Hand_Global.jpg" width="203" height="111" border="0"></a></td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td><div align="center">(d)</div></td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td width="32%"><div align="center"><strong>Scene</strong></div></td>
                        <td width="35%"><div align="center"><strong>Direct</strong></div></td>
                        <td width="33%"><div align="center"><strong>Global</strong></div></td>
                      </tr>
                    </tbody></table>
                      <table width="100%" border="0" cellspacing="10" cellpadding="0">
                        <tbody><tr>
                          <td>Here,  we show separation results for hands of people from four different  nationalities - (a) African American female, (b) Asian Indian male, (c)  Spanish male and (d) Chinese male. Notice how the direct component  mainly includes the surface reflection due to oils and lipids on the  skin. It also reveals the details of the micro-geometry of the skin  surface.&nbsp; It is also interesting to notice the difference in the  micro-geometry of the different samples. Most of the color of the skin  comes from subsurface scattering, as seen in the global image. In  contrast to the direct component, the global&nbsp; component does not reveal  the roughness of the skin’s surface and only includes albedo variations  due to the melanin levels within the skin.</td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                    </tbody></table></td>
                </tr>
            </tbody></table></td>
          </tr>
          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                <tbody><tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="47%"><strong>Hand with Tattoo</strong></td>
                        <td width="53%"><div align="right"><strong>Method : Checkerboard<br>
                        </strong></div></td>
                      </tr>
                    </tbody></table>
                      <div align="right"></div></td>
                </tr>
                <tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Tattoo_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image7141.jpg" width="202" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Scene</strong></div></td>
                        <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Tattoo_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image7951.jpg" width="203" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Direct</strong></div></td>
                        <td width="33%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Tattoo_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image6011.jpg" width="203" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Global</strong></div></td>
                      </tr>
                    </tbody></table>
                      <table width="100%" border="0" cellspacing="10" cellpadding="0">
                        <tbody><tr>
                          <td>Here  we show the separation results for a hand with a green tattoo. Notice  that the green tattoo appears in the direct image and is very dark in  the global image, indicating that the pigments of the tattoo ink are  more opaque than the tissues of the skin.</td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                    </tbody></table></td>
                </tr>
            </tbody></table></td>
          </tr>
          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                <tbody><tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td><strong>Face</strong></td>
                        <td><div align="right"><strong>Method : Singe Image</strong></div></td>
                      </tr>
                    </tbody></table>
                      <div align="right"></div></td>
                </tr>
                <tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Face_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Face_Scene.jpg" width="203" height="256" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Scene</strong></div></td>
                        <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Face_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Face_Direct.jpg" width="203" height="256" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Direct</strong></div></td>
                        <td width="33%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Face_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Face_Global.jpg" width="203" height="256" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Global</strong></div></td>
                      </tr>
                    </tbody></table>
                      <table width="100%" border="0" cellspacing="10" cellpadding="0">
                        <tbody><tr>
                          <td><div v:shape="_x0000_s1286">
                            <p>The separation results for this face were obtained from a single high resolution image (<a href="https://cave.cs.columbia.edu/old/projects/separation/images/SingleImage_2-2.jpg">click here</a> to see the image) captured with the face lit&nbsp; by a high frequency  stripe pattern. By assuming smoothness of surface properties, low  resolution direct and global images are computed from the captured  image. The resolution of the results shown above are one-fourth the  resolution of the captured image. Similar to the case of hands, the  specular reflections due to oils and lipids are captured in the direct  image. The micro-geometry of the skin is however not visible due to  reduction in resolution. The skin tone is produced by subsurface  scattering and is measured in the global image.</p>
                          </div></td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                    </tbody></table></td>
                </tr>
                <tr>
                  <td><table width="100%" border="0" cellpadding="10" cellspacing="0" bordercolor="#000000" bgcolor="#F6F4EC">
                      <tbody><tr>
                        <td><img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image5421.jpg" width="202" height="220"></td>
                        <td valign="top"><p><strong>Oiliness Changes: </strong>This video shows novel images generated by scaling the direct image and adding it back to the global image. <a href="https://cave.cs.columbia.edu/old/projects/separation/videos/Oil-Changes.mpg">MPEG</a>, <a href="https://cave.cs.columbia.edu/old/projects/separation/videos/Oil-Changes.mov">Quicktime</a></p></td>
                      </tr>
                      <tr>
                        <td width="32%"><img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image5431.jpg" width="202" height="220"></td>
                        <td width="68%" valign="top"><p><strong>Skin Tone Changes: </strong>This  video shows novel images generated by changing the hue of the skin in  the global image and adding it back to the direct image.<br>
                          <a href="https://cave.cs.columbia.edu/old/projects/separation/videos/Tone-Changes.mpg">MPEG</a> , <a href="https://cave.cs.columbia.edu/old/projects/separation/videos/Tone-Changes.mov">Quicktime</a></p>                        </td>
                      </tr>
                  </tbody></table></td>
                </tr>
            </tbody></table></td>
          </tr>
          
         
          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                <tbody><tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="47%"><strong>Face With and Without Makeup</strong></td>
                        <td width="53%"><div align="right"><strong>Method : Checkerboard<br>
                        </strong></div></td>
                      </tr>
                    </tbody></table>
                      <div align="right"></div></td>
                </tr>
                <tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/WithoutMakeup_Scene.png"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/WithoutMakeup_Scene.png" width="203" height="231" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/WithoutMakeup_Direct.png"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/WithoutMakeup_Direct.png" width="203" height="231" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/WithoutMakeup_Global.png"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/WithoutMakeup_Global.png" width="203" height="231" border="0"></a></td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td><div align="center">(a) Without makeup</div></td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Withmakeup_Scene.png"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Withmakeup_Scene.png" width="203" height="231" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Withmakeup_Direct.png"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Withmakeup_Direct.png" width="203" height="231" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Withmakeup_Global.png"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Withmakeup_Global.png" width="203" height="231" border="0"></a></td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td><div align="center">(b) With makeup</div></td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td width="32%"><div align="center"><strong>Scene</strong></div></td>
                        <td width="35%"><div align="center"><strong>Direct</strong></div></td>
                        <td width="33%"><div align="center"><strong>Global</strong></div></td>
                      </tr>
                    </tbody></table>
                      <table width="100%" border="0" cellspacing="10" cellpadding="0">
                        <tbody><tr>
                          <td>Similar to the previous skin examples, when no makeup is applied to the face, 
                           the separation results show specular reflections in the direct image and the skin tone 
                           in the global image. However when cosmetics is applied, the face appears matte in the direct image
                           due to a layer of powder. The specularities in the direct image are mainly due to the skin illuminators 
                           applied over the powder. In the global image notice that there is hardly any subsurface scattering in the 
                           lip area due to the presence of the think lipstick layer.</td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                    </tbody></table></td>
                </tr>
            </tbody></table></td>
          </tr>
                    
          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                <tbody><tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="47%"><strong>Different Hairs</strong></td>
                        <td width="53%"><div align="right"><strong>Method : Checkerboard<br>
                        </strong></div></td>
                      </tr>
                    </tbody></table>
                      <div align="right"></div></td>
                </tr>
                <tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Hair_Blonde_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Hair_Blonde_Scene.jpg" width="203" height="123" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Hair_Blonde_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Hair_Blonde_Direct.jpg" width="203" height="123" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Hair_Blonde_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Hair_Blonde_Global.jpg" width="203" height="123" border="0"></a></td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td><div align="center">(a)</div></td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Hair_Brown_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Hair_Brown_Scene.jpg" width="203" height="122" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Hair_Brown_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Hair_Brown_Direct.jpg" width="203" height="122" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Hair_Brown_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Hair_Brown_Global.jpg" width="203" height="122" border="0"></a></td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td><div align="center">(b)</div></td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Hair_Auburn_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Hair_Auburn_Scene.jpg" width="203" height="123" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Hair_Auburn_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Hair_Auburn_Direct.jpg" width="203" height="123" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Hair_Auburn_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Hair_Auburn_Global.jpg" width="203" height="123" border="0"></a></td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td><div align="center">(c)</div></td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Hair_Black_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Hair_Black_Scene.jpg" width="203" height="123" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Hair_Black_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Hair_Black_Direct.jpg" width="203" height="123" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Hair_Black_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Hair_Black_Global.jpg" width="203" height="123" border="0"></a></td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td><div align="center">(d)</div></td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td width="32%"><div align="center"><strong>Scene</strong></div></td>
                        <td width="35%"><div align="center"><strong>Direct</strong></div></td>
                        <td width="33%"><div align="center"><strong>Global</strong></div></td>
                      </tr>
                    </tbody></table>
                      <table width="100%" border="0" cellspacing="10" cellpadding="0">
                        <tbody><tr>
                          <td>Separation  results for four types of hairs - (a) Blonde, (b) Brown, (c) Auburn and  (d) Black. Note that the direct image makes all the hairs look like  dark tan hair.&nbsp; The direct image reveals the complex BRDF of individual  hair fibers, which is due to the nested-cone structure of each fiber.  One can observe the complex structures of the specular highlights as  well as rainbow scattering effects that are known to occur in hair  fibers. The direct image also shows some random speckle artifacts due  to the color demosaicing operation applied to the captured images. The global images for first three hair samples have the appearance of a flat texture such as that of finished wood and is almost non existent in the case of black hair. The global component arises from multiple  reflections of light between the hair fibers and is seen to account for  the color of the hair.</td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                    </tbody></table></td>
                </tr>
            </tbody></table></td>
          </tr>

          <tr>
            <td>&nbsp;</td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>